<template>
  <div class="item-list">
    <p class="p1"><span class="span1">{{item.comName}}</span><span>审核通过</span></p>
    <p>统一征信代码: <span>{{item.uscc}}</span></p>
    <p>注册时间: <span>{{conversionTime(item.createTime)}}</span></p>
    <p>环保负责人姓名: <span>{{item.managerName}}</span></p>
    <p>环保负责人电话: <span>{{item.managerPhone}}</span></p>
    <div class="btns">
      <van-button type="default" size="small" @click="visitClick(item)">走访</van-button>
      <van-button type="default" size="small" @click="Recharge(item.id)">充值</van-button>
      <van-button type="default" size="small" @click="modify()">修改</van-button>
      <van-button type="default" size="small" @click="seeClick()">查看</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminShtg',
  props: [
    'item'
  ],
  watch: {
    item(newval, oldval) {
      // console.log(newval)
    }
  },
  data () {
    return {
      detailsId: ''
    }
  },
  created() {
    this.detailsId = this.item.id
  },
  methods: {
    visitClick(item) { // 回访记录
      this.$router.push({path: 'Visitrecord', query: {uscc: item.uscc}})
    },
    seeClick() { // 查看资料
      this.$router.push({path: 'Basic', query: {admin: true, detailsId: this.detailsId}})
    },
    modify() { // 修改资料
      this.$router.push({path: 'Details', query: {admin: true, id: this.detailsId}})
    },
    Recharge(id) { // 充值
      this.$router.push({path: 'Virtualcurrency', query: {id: id}})
    }
  }
}

</script>
<style lang="scss" scoped>
.item-list {
  margin: 7px 0;
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  .p1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    .span1 {
      color: #333;
      font-weight: 600;
      font-size: 18px;
    }
  }
  p {
    margin-bottom: 3px;
    color: #999;
    font-size: 16px;
    span {
      color: #333;
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
::v-deep .van-button--small {
  margin-right: 5px;
}
</style>
