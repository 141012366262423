<template>
  <div class="warp">
    <van-field
      v-model="query.comName"
      center
      clearable
      label="企业搜索"
      placeholder="请输入企业名称"
    >
      <template #button>
        <van-button size="small" type="primary" @click="searchClick"
          >搜索</van-button
        >
        <van-button size="small" type="primary" @click="openvisits"
          >走访</van-button
        >
      </template>
    </van-field>
    <van-tabs v-model="activeBQ" @click="onClick">
      <van-tab title="待审核">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div v-for="(item, index) in itemList" :key="index">
              <admin-shz :item="item" :key="index"></admin-shz>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="审核通过">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div v-for="(item, index) in itemList" :key="index">
              <admin-shtg :item="item" :key="index"></admin-shtg>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="审核未通过">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div v-for="(item, index) in itemList" :key="index">
              <admin-shjj :item="item" :key="index"></admin-shjj>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import { companyList } from '@/api/enterprise.js';
  import AdminShz from '@/views/administration/adminchild/Adminshz.vue';
  import AdminShtg from '@/views/administration/adminchild/Adminshtg.vue';
  import AdminShjj from '@/views/administration/adminchild/Adminshjj.vue';
  export default {
    components: {
      AdminShz,
      AdminShtg,
      AdminShjj,
    },
    data() {
      return {
        activeBQ: 0,
        loading: false, // 上拉加载状态
        finished: false, // 是否已加载完成，加载完成后不再触发load事件进去多次加载请求
        refreshing: false, // 下拉刷新状态
        query: {
          isPass: 0,
          pageNum: 1,
          pageSize: 10,
          comName: undefined,
        },
        total: undefined,
        itemList: [], // 保存订单lsit
      };
    },
    activated() {
      this.itemList = [];
      this.companyList();
    },
    methods: {
      openvisits() {
        this.$router.push({ path: 'Visitrecords' });
      },
      searchClick() {
        // 搜索
        this.itemList = [];
        this.companyList();
      },
      companyList() {
        // 获取默认显示待接单
        companyList(this.query).then((res) => {
          if (res.data.code === 200) {
            if (this.itemList.length) {
              if (this.loading) {
                // 上拉加载
                if (res.data.rows.length < 10) {
                  // 没有更多数据
                  this.finished = true; // 表示上拉加载完毕
                }
                this.itemList = this.itemList.concat(res.data.rows); // 上拉加载新数据添加到数组中
                this.$nextTick(() => {
                  // 在下次 DOM 更新循环结束之后执行延迟回调
                  this.loading = false; // 关闭上拉加载中
                });
              }
              if (this.refreshing) {
                // 关闭下拉刷新
                this.refreshing = false; // 关闭下拉刷新
                this.itemList = res.data.rows; // 重新给数据赋值
              }
            } else {
              this.finished = false;
              this.loading = false;
              this.refreshing = false;
              this.itemList = res.data.rows;
            }
            this.total = res.data.total;
          }
        });
      },
      onClick(name) {
        // 点击tab标签
        this.itemList = [];
        this.query.pageNum = 1;
        this.query.isPass = name;
        this.companyList();
      },
      onLoad() {
        // 上拉加载
        setTimeout(() => {
          if (this.itemList.length < this.total) {
            this.loading = true;
            this.query.pageNum++;
            this.companyList();
          } else {
            this.finished = true;
          }
        }, 300);
      },
      onRefresh() {
        // 下拉刷新
        setTimeout(() => {
          // 下拉刷新后，可以重新触发上拉加载
          this.finished = false;
          // 将 refreshing 设置为 true，表示处于刷新状态
          this.refreshing = true;
          this.query.pageNum = 1;
          this.companyList();
        }, 300);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .warp {
    padding-bottom: 0;
  }
  ::v-deep .van-tabs__content {
    height: calc(100vh - 95px);
    overflow: scroll;
  }
  ::v-deep .van-tabs__line {
    background-color: #079ef2;
  }
  ::v-deep .van-tab__pane,
  .van-tab__pane-wrapper {
    height: 100%;
  }
  ::v-deep .van-pull-refresh {
    height: 100%;
    overflow: scroll;
  }
</style>
<style lang="scss">
  .van-field__body {
    .van-field__button {
      display: flex;
      justify-content: space-between;
      width: 40%;
    }
  }
</style>
