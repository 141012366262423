<template>
  <div class="item-list">
    <p class="p1"><span class="span1">{{item.comName}}</span><span>审核中</span></p>
    <p>统一征信代码: <span>{{item.uscc}}</span></p>
    <p>注册时间: <span>{{conversionTime(item.createTime)}}</span></p>
    <p>环保负责人姓名: <span>{{item.managerName}}</span></p>
    <p>环保负责人电话: <span>{{item.managerPhone}}</span></p>
    <div class="btns">
      <van-button type="default" size="small" @click="modify()">修改</van-button>
      <van-button type="default" size="small" @click="examine()">审核</van-button>
    </div>
    <van-dialog v-model="isShowsh" title="审核" show-cancel-button @confirm="addconfirm">
      <van-radio-group v-model="query.isPass" direction="horizontal">
        <span class="jffs">审核状态</span>
        <van-radio name="1">审核通过</van-radio>
        <van-radio name="2">审核不通过</van-radio>
      </van-radio-group>
      <van-field 
        v-model="query.noPassReason"
        rows="2"
        autosize
        label="不通过原因"
        type="textarea"
        maxlength="50"
        placeholder="请输入原因"
        show-word-limit />
    </van-dialog>
  </div>
</template>

<script>
import { companyreview } from '@/api/enterprise.js'
import { Dialog } from 'vant'
import { Toast } from 'vant'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  name: 'AdminShz',
  props: [
    'item'
  ],
  watch: {
    item(newval, oldval) {
      // console.log(newval)
    }
  },
  data () {
    return {
      isShowsh: false,
      query: {
        id: '',
        isPass: '',
        noPassReason: ''
      }
    }
  },
  created() {
    this.query.id = this.item.id
  },
  methods: {
    modify() { // 修改资料
      this.$router.push({path: 'Details', query: {admin: true, id: this.query.id}})
    },
    examine(item) { // 审核
      this.isShowsh = true
    },
    addconfirm() { // 审核确认
      companyreview(this.query).then(res => {
        if (res.data.code === 200) {
          if (this.query.isPass == 1) {
            this.$router.push({path: 'Enterpriselist'})
            Toast('审核通过')
          } else {
            this.$router.push({path: 'Enterpriselist'})
            Toast('审核拒绝')
          }
          location.reload()
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.item-list {
  margin: 7px 0;
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  .p1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    .span1 {
      color: #333;
      font-weight: 600;
      font-size: 18px;
    }
  }
  p {
    margin-bottom: 3px;
    color: #999;
    font-size: 16px;
    span {
      color: #333;
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
.jffs {
  width: 70px;
  color: #646566;
  font-size: 14px;
}
::v-deep .van-radio-group--horizontal {
  background-color: #fff;
  padding-left: 15px;
  height: 44px;
  align-content: center;
  border-bottom: 1px solid #ebedf0;
  font-size: 14px;
}
::v-deep .van-dialog__header {
  padding-top: 15px;
}
::v-deep .van-button--small {
  margin-right: 5px;
}
</style>
